@import "../../../styles/helper";

.auction-details-container {
  padding: 10px;
  flex: 1 1;
  @media (max-width: 480px) {
    padding: 10px 16px;
  }
  &.auction--detail{
    overflow-y: auto;
  }
  .details-and-latest-bids {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    .details-container {
      flex: 1 1 70%;
      background-color: $white;
    }

    .auction-latest-bids {
      flex: 1 1 30%;
      display: flex;

      .latest-bids-container {
        max-height: 600px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .congratulations-image-container {
    position: relative;

    .congratulations-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .icon {
      color: $n13;
      font-size: 51px;
      line-height: normal;
      margin-bottom: 15px;

      &.ri-information-fill {
        color: $s5;
      }
    }

    .congratulations-heading {
      font-size: 20px;
      font-weight: 600;
      color: $white;
    }

    .congratulations-desc {
      font-size: 14px;
      color: $n14;
    }
  }

  .auction-details-heading {
    color: $s5;
    font-size: 18px;
    padding: 15px;
    font-weight: 600;
    background-color: $white;

    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 4px;
      background-color: $s5;
      border-radius: 16px;
      margin-right: 10px;
      vertical-align: middle;
    }

    @include dark {
      background-color: $dark;
    }
  }

  .auction-details-image {
    width: 100%;
    height: 380px;
    border-radius: 0;
    border: 1px solid $n6;
    background-color: $n6;
    display: flex;
    justify-content: center;
    align-items: center;

    .ri-image-add-line {
      font-size: 50px;
      color: $n4;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  .auction-details {
    background-color: $white;
    padding: 15px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    .auction-name {
      font-size: 16px;
      font-weight: 600;
    }

    .title-heading-symbol {
      line-height: 1.3;
      margin-bottom: 8px;
    }

    .title-symbol {
      font-size: 14px;
      color: $n4;

      span {
        color: $n2;
      }
    }

    .auction-desc {
      font-size: 14px;
      font-weight: 400;
      color: $n4;
    }

    @include dark {
      background-color: $dark;

      .auction-name {
        color: $white;
      }
      .auction-desc {
        color: $n5;
      }

      .title-symbol {
        span {
          color: $n5;
        }
      }
    }
  }

  .auction-stats-container {
    background-color: $white;
    border-radius: 4px;
    padding-block: 15px;
    margin-bottom: 15px;

    &.stats-background{
      background-color: transparent !important;
      padding-top: 0;
      margin-bottom: 10px;
      padding-bottom: 0;
    }

    @include dark {
      background-color: $dark;
    }
  }


  .auction-progress-bar {
    .CircularProgressbar-path {
      stroke: $p4;
      stroke-linecap: butt;
    }

    &.time-red {
      .CircularProgressbar-path {
        stroke: $p3;
      }
    }

    &.time-over {
      .CircularProgressbar-trail {
        stroke: $p3;
      }
    }

    @include dark {
      .CircularProgressbar-trail {
        stroke: $n3;
      }

      &.time-over {
        .CircularProgressbar-trail {
          stroke: $p3;
        }
      }
    }
  }

  .inner-time-circle {
    width: 160px;

    .CircularProgressbar-text {
      font-size: 18px;
      stroke: $p1;
    }

    .CircularProgressbar-path {
      stroke: $p1;
    }

    .CircularProgressbar-trail {
      stroke: $p1_12;
    }
  }

  .radial-stick {
    height: 6%;
  }

  .stats-row {
    display: grid;
    grid-template-columns: 8fr 4fr;
    align-items: center;
    gap: 20px;

    &.stats-row-1{
      align-items: flex-start !important;
      gap: 10px;
      grid-template-columns: calc(70% - 5px) calc(30% - 5px);
    }
  }

  .max-bid-details {
    display: grid;
    gap: 5px 10px;
    grid-template-columns: 6fr 6fr;
    grid-column: 1 / span 2;
    align-items: baseline;

    .button-red-light {
      font-size: 12px;
      font-weight: 500;
    }

    .stat-value {
      display: flex;
      justify-content: space-between;
    }
  }

  .stats {
    margin-bottom: 20px;
    padding-inline: 15px;

    &.stats-1{
      background-color: $white;
      border-left: 0;
      margin-bottom: 10px;
      padding-bottom: 10px;

      @include dark {
        background-color: $dark;
      }
    }

    .auction-timer-stats{
      display: grid;
      grid-template-columns: 4fr 8fr;
      padding-bottom: 15px;

      .stats-details{
        padding-left: 50px;
        grid-template-columns: 6fr 6fr;
        border-left: 1px solid $n6;

        @include dark {
          border-left: 1px solid $n3;
        }
      }

     
    }

    .stats-percent {
      height: 200px;
      width: 200px;
      margin: 0 auto 30px;
      border-radius: 50%;
      position: relative;

      .stats-item {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .stats-details {
      flex: 1 1;
      display: grid;
      gap: 15px;
      grid-template-columns: 4fr 4fr 4fr;
    }

    .row-colon {
      .stat-label {
        position: relative;

        &:after {
          content: ":";
          display: inline-block;
          color: black;
          position: absolute;
          left: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 1;
        }
      }
    }

    .disable-step {
      .stat-label,
      .stat-value,
      .bid-multiplier-container {
        opacity: 0.4;
      }
    }

    .stat-label {
      font-size: 12px;
      color: $n4;
      font-weight: 500;
      text-transform: capitalize;
    }

    .stat-value {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
    }

    @include dark {
      .stat-label {
        color: $n5;
      }

      .row-colon {
        .stat-label {
          &:after {
            color: $white;
          }
        }
      }
    }
  }

  .bid-place-container {
    border-left: 1px solid $n6;

    &.stats-1{
      margin-bottom: 0;
    }

    .stats-details {
      grid-column: 1 / span 2;
      grid-template-columns: auto auto;
      border-bottom: 1px solid $n6;
      margin-bottom: 15px;
      padding-bottom: 15px;
      align-items: baseline;

      &.rm-border {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    .your-bid-price {
      grid-template-columns: auto;
      text-align: center;
      border-bottom: 0;
      padding-bottom: 0;

      .max-bid-text {
        color: $p3;
        font-size: 12px;
        grid-column: 1 / span 2;
      }

      .stat-value {
        font-size: 24px;
      }
    }

    .last-bid-status {
      background-color: $lightRed;
      color: $youtube;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 24px;
      margin-left: 5px;

      &.success {
        background-color: $lightGreen;
        color: black;
      }
    }
  }

  .buy-btn {
    width: 90%;

    .circle-loader {
      width: auto;
    }
  }

  .auction-detail-features {
    background-color: $white;
    display: grid;
    grid-template-columns: 7fr 5fr;
    padding: 20px 10px;

    .auction-details {
      padding: 0 15px 0 0;
    }

    .bid-img {
      width: 100px !important;
      height: 100px !important;
    }

    .bid-detail {
      .name {
        font-weight: 600 !important;
      }

      .time,
      .name {
        font-size: 20px !important;
      }
    }

    .stats {
      display: grid;
      align-items: center;
      margin-bottom: 0;
      border-left: 1px solid $n6;
    }
  }

  .buy-now-section {
    position: relative;
    height: 230px;
    background-color: $n22_1;
    border-radius: 7px;
    text-align: center;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
    }

    .buy-now-details {
      position: absolute;
      font-size: 24px;
      font-weight: 600;
      top: 50%;
      width: 100%;
      transform: translate(0, -50%);
      display: flex;
      flex-direction: column;
      line-height: 1.5;

      small {
        font-size: 16px;
        font-weight: 500;
      }
    }

    @include dark {
      background-color: $n23;
    }
  }

  @include dark {
    .auction-details-image {
      border-color: $n19;
    }

    .details-container,
    .auction-detail-features {
      background-color: $dark;
    }

    .auction-detail-features {
      .stats {
        border-left-color: $n18;
      }
    }

    .bid-place-container {
      border-left-color: $n18;

      .stats-details {
        border-bottom-color: $n18;
      }
    }
  }

  .user-input-bid-container {
    grid-column: 1 / span 2;

    .input__label {
      color: $n22;
    }

    .input__button {
      color: $p4;
      background-color: transparent !important;
      border: 2px solid $p4;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
      min-width: 125px;
      gap: 0;
    }

    .input__text-fields {
      background-color: $white;
      border: 1px solid $n6;
    }

    .input__text {
      margin-right: 10px;
      background-color: $n7;
      border-radius: 24px;
      display: inline-block;
      padding: 2px 10px;
    }

    .input__label {
      display: flex;
      gap: 5px;
    }

    .info-icon {
      display: inline-block;
      cursor: pointer;

      .ri-information-fill {
        font-size: 20px;
      }
    }

    .react-tooltip {
      max-width: 300px;
      border: 1px solid $n6;
      background-color: $white;
      color: $n22;
      font-size: 12px;
    }

    .react-tooltip-arrow {
      border-right: 1px solid $n6;
      border-bottom: 1px solid $n6;
    }

    @include dark {
      .input__text-fields {
        background-color: $dark;
        border-color: $n18;
      }

      .input__text {
        background-color: $n2;
      }

      .react-tooltip {
        max-width: 300px;
        border-color: $n18;
        background-color: $n2;
        color: $white;
      }

      .react-tooltip-arrow {
        border-right-color: $n18;
        border-bottom-color: $n18;
      }
    }
  }

  .bid-multiplier-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .bid-plus-minus {
      display: inline-flex;
      border: 1px solid $n6;
      padding: 8px;
      color: $n3;
      border-radius: 4px;
      width: 32px;
      line-height: 1;
      justify-content: center;
      cursor: pointer;
    }

    .bid-value {
      background-color: $n6;
      font-weight: 600;
    }

    @include dark {
      .bid-plus-minus {
        border-color: $n18;
        color: $n6;
      }

      .bid-value {
        color: $white;
        background-color: $n18;
      }
    }
  }
}
