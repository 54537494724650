@import "../../../../styles/helper.scss";

.auction-data-room-container {
  background-color: $white;

  .data-room-files {
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 10px;
    padding: 15px;

    .data-room-item {
      display: grid;
      gap: 15px;
      grid-template-columns: 50px auto 115px;

      .data-room-img {
        width: 50px;
        height: 50px;
        border: 1px solid $n5;
        padding: 8px;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .data-room-details {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: start;
      word-break: break-all;
      .name {
        cursor: pointer;
      }

      span {
        font-size: 12px;
        color: $n4;
      }
    }

    .button {
      border-radius: 4px;
      height: 40px;
      min-height: 40px;
      font-weight: 500;
      font-size: 14px;
      padding-inline: 10px;
    }
  }

  @include dark {
    background-color: $dark;

    .data-room-details {
      span {
        color: $n5;
      }
    }
  }
}

.edit-history-container {
  padding: 20px;

  .history-item {
    display: flex;
    column-gap: 15px;
    align-items: flex-start;
    margin-bottom: 25px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      border-left: 1px solid $n26;
      position: absolute;
      top: 20px;
      bottom: -40px;
      left: 20px;
    }

    &:last-child:before {
      display: none;
    }
  }

  .edit-img {
    width: 40px;
    z-index: 1;
    background-color: $white;
  }

  .edit-detail {
    flex: 1 1;
  }

  .edit-history-heading {
    font-size: 14px;
    color: $n22;

    b {
      color: black;
      font-weight: 500;
    }
  }

  .edit-history-time {
    color: $n15;
  }

  .history-file {
    margin-top: 8px;
    display: flex;
    align-items: center;

    .edit-img {
      background-color: $n20;
      border-radius: 50%;
      padding: 5px;
      height: 40px;
      margin-right: 10px;
    }

    .file-name {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      gap: 10px;
      .file-docname{
        max-width: 324px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .file-size {
        color: $n22;
        gap: 5px;

        &:before {
          content: "";
          display: inline-block;
          border: 2px solid $n16;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .view-btn {
      background: $blue6per;
      color: $blue;
      font-size: 12px;
      font-weight: 500;
      width: 70px;
      margin-left: 20px;

      &:hover,
      &.active {
        background: darken($blue6per, 8);
      }
    }
  }

  @include dark {
    .history-item {
      &:before {
        border-left-color: $n4;
      }
    }

    .edit-img {
      background-color: $dark;
    }

    .edit-history-heading {
      color: $n16;

      b {
        color: $white;
      }
    }
  }
}
@media (max-width: 480px) {
  .auction-data-room-container {
    .data-room-files{
      grid-template-columns: 12fr;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 15px;
      padding-bottom: 0;
      .data-room-details{
        font-size: 16px;
      }
    }
  }
}