@import styles/helpers

.main
    overflow: hidden
    display: flex
    align-items: center
    padding: 9px 16px
    background: $n8
    border-bottom: 1px solid $n6
    gap: 35px
    justify-content: space-between
    width: 100%
    +t
        display: block
        margin: -8px -8px 0
    +m
        padding: 16px
    +d
        flex-direction: column
        align-items: flex-start
        gap: 10px
    +dark
        background: $dark
        border-color: $n2
.captableOpen
    position: sticky
    top: 0px
    z-index: 9
    background-color: #ffffff
    .publishNow__btnContainer__saveToDraft
        margin-right: 24px
.details
    display: flex
    align-items: center
    gap: 16px
    +t
        margin-bottom: 12px
    +m
        display: block
        display: flex
        justify-content: space-between

    +d
        gap: 32px
.sbaDetails
    width: 100%
    align-items: flex-start
    +s
        flex-direction: column
        position: static !important
        padding: 0 !important
.sbacolumn
    display: grid
    grid-template-columns: auto auto auto
    grid-gap: 0.5rem
    justify-content: space-between
    flex: 1 1
    +s
        margin-top: 60px
        padding: 0 16px
    

.musicDetails
    // gap: 80px  

.box
    +s
        width: 100%
    .content
        display: flex
        align-items: center
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .positive
        color: $p4
    .negative
        color: $p3
        +caption-bold-2

        svg
            margin-right: 4px
            fill: $n4
    &:not(:last-child)
        +m
            margin: 0 0 8px
        +s
            margin: 0

    .chat_btn
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        color: #1472FF
        border: 1px solid #1472FF
        padding: 8px 12px
        border-radius: 4px
        gap: 4px
        margin-top: 12px
        &[disabled]
            cursor: not-allowed

// .sbaLine
//     display: flex
//     flex-direction: column
//     align-items: center
//     text-align: center
//     width: 200px
//     margin: 0 60px 0 30px
//     .img
//         width: 50px
//         height: 50px
//     .info
//         margin-top: 10px

.sbaLine
    display: flex
    align-items: center
    text-align: left
    max-width: 500px
    margin-right: 30px
    +s
        position: absolute
        width: 100vw
        background: $n8
        z-index: 9
        padding: 8px 16px
        +dark
            background: #17181b
    .info
        max-width: 350px
        text-overflow: ellipsis
    .stock_name
        max-width: 350px
        text-overflow: ellipsis

.line
    display: flex
    align-items: center
    text-align: left
    max-width: 800px
    .info
        max-width: 800px
        text-overflow: ellipsis
    .stock_name
        max-width: 800px
        text-overflow: ellipsis

.line1
    display: flex
    align-items: center
    width: fit-content

.Lheader
    display: flex
    align-items: center
    width: 130px

.img
    width: 50px
    height: 50px
    object-fit: contain
    font-size: 20px
    background-color: #d7dff5
    position: relative
    +dark
        background: $n2
    display: flex
    border-radius: 50%
    justify-content: center
    align-items: center
    img
        height: 48px
        width: 50px
        border-radius: 50%

.accreditationLabel
    position: absolute
    top: calc(100% - 34px)
    width: 60px !important
    border-radius: unset !important

.info
    margin-bottom: 4px
    +body-bold-1
    line-height: 1.32
    color: $n1
    padding-left: 12px
    font-size: 16px
    max-width: 150px
    white-space: nowrap
    overflow: hidden
    +dark
        color: $n8

.stock_name
    color: $n4
    padding-left: 12px
    max-width: 150px
    overflow: hidden
    white-space: nowrap
    +m
        width: 90px

.icon
    font-size: 14px
    color: $s3
    padding-right: 12px
    cursor: pointer
    .iconAdded
        color: $s3
    .iconRemoved
        color: $n4
    +t
        display: none

.category
    margin-left: 4px
    padding: 7px 8px 5px
    border-radius: 12px

.price
    margin-bottom: 4px
    text-align: right
    +body-bold-1
    color: #000
    font-style: normal
    font-weight: 600
    font-size: 16px
    +dark
        color: $white
    +m
        font-size: 16px

.currency
    color: $n9
    font-size: 12px
    font-style: normal
    font-weight: 500

.list
    display: flex
    gap: 12px
    +d
        margin-top: 8px
    +t
        margin-bottom: 8px
    +m
        display: flex
        flex-wrap: wrap
        flex-direction: row
        justify-content: center
        gap: 0px !important

.item
    padding: 0 36px 0 0
    &:last-child
        border: none
    +d
        flex: 0 0 auto
        width: auto
    +t
        flex: 1 0 auto
    +m
        //flex: 0 0 50%
        //width: 50%
        margin: 12px 0
        display: flex
        align-items: left
        flex-direction: column
    .content
        display: flex
        font-weight: 500
        color: $n1
        font-size: 14px
        +m
            margin-left: 0px
            display: flex
            justify-content: flex-start
        +t
            justify-content: center
        +dark
            color: $n8
.itemBorder
    border-right: 1px solid #FFFFFF1F
    
.subtitlesba
    display: flex
    align-items: center
    +caption-1
    color: $n4

.subtitle
    display: flex
    align-items: center
    margin-block-end: 4px
    +t
        justify-content: center
    +caption-2
    font-size: 14px
    font-weight: 400
    color: $n4
    svg
        margin-right: 4px
        fill: $n4

.skeleton_asset
    min-width: 180px
    padding-left: 12px
    +t
    min-width: 120px

.imagewrapper
    max-width: 48px
    max-height: 48px
    border-radius: 24px
    height: 100%
    width: 100%
.imagefromcontiner
    width: 24px
    overflow: hidden
    border-radius: 100px
    z-index: 10
    margin-left: 7px
    position: relative
    top: -5px
    .imagefrom
        max-width: 24px
.imagetocontiner
    position: relative
    top: -32px
    .imageto
        max-width: 24px
        position: absolute
        left: 17px
        top: -6px
        width: 100%
        border-radius: 23px
    .skeleton_asset
        min-width: 180px
        padding-left: 12px

.music
    height: 192px !important
    background: linear-gradient(180deg, rgba(245, 0, 0, 0.48) 0%, rgba(245, 0, 0, 0) 100%) !important
    border-bottom: unset !important
    position: relative
    gap: 0px

.music_img
    width: 120px
    height: 120px
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.24)
    border-radius: 4px !important
    object-fit: contain
    font-size: 60px
    color: #ffffff
    img
        height: 120px
        width: 120px
        border-radius: unset
        object-fit: contain
.music_devide
    height: 48px
    width: 14px
    border-right: 1px solid rgba(255, 255, 255, 0.24)
.sba_devide
    height: 30px
    min-width: 14px
    border-right: 1px solid #FFFFFF3D
    margin-right: 10px   
.music_other_details
    position: absolute
    left: 148px
    top: 124px
    display: flex
    width: calc( 100% - 172px )
    justify-content: space-between
    alig-items: center
    +d
       top: 98px  
    &__left
        display: flex
        align-items: center
        gap: 4px
        font-size: 12px
        .albom_img
            width: 24px
            height: 24px
            object-fit: contain
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.24)
            img
                width: 24px
                height: 24px
                object-fit: contain

    &__right
        font-size: 24px
        display: flex
        gap: 8px
        justify-content: center
        .youtube
            color: $youtube
            cursor: pointer
        .spotify
            color: $spotify
            cursor: pointer
        .apple
            color: $white
            cursor: pointer
.music_release
    display: flex
    align-items: center
    padding-bottom: 16px
    gap: 8px
    i
        font-size: 8px

.songName
    font-size: 14px
    font-weight: 500
    line-height: 20px
    padding-left: 12px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 800px
    +dark
        color: $n8


.private_assets_name
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 150px
.gotodownIcon
    font-size: 24px
    color: #ED8927
    cursor: pointer
.crypto
    padding-block: 6px

.crypto_price
    min-width: 130px

.publishNow__btnContainer
    display: flex
    justify-content: center
    align-content: center
    gap: 8px
    &__saveToDraft
        height: 48
        border-radius: 4px
        color: #363C45
        min-width: 134px
        padding: 12px 16px
        background: #0000000F
        font-weight: 500
        font-size: 16px
        line-height: 24px
        +dark
            color: $white
            background: #FFFFFF0F

    &__publishNow
        height: 48
        border-radius: 4px
        padding: 12px 16px
        background: #1973FA
        color: $white
        min-width: 134px
        font-weight: 500
        font-size: 16px
        line-height: 24px
.publishBtnContainer
    display: flex
    gap:8px
    align-items: center
.publishNow_pendingConatiner
    margin-right:16px
    padding: 8px 12px
    border-radius: 24px
    border:1px solid $n53
    &__pending
        color: $n53
        font-size: 16px
        font-weight: 500
.publishNow_rejectedContainer
    margin-right:16px
    padding: 8px 12px
    border-radius: 24px
    border:1px solid $n29
    &__rejected
        color: $n29
        font-size: 16px
        font-weight: 500

@media ((max-device-width: 500px))
    .item
        margin: 4px 0
    .main
        margin: 0
        padding: 0
        margin-top: -2px
    .details
        margin: 0
        position: absolute
        z-index: 10
        background: $n8
        width: 100%
        padding: 8px 16px
        gap: 0
        padding-top: 8px
        +dark
            background: $dark

    .list
        display: grid
        gap: 10px
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(2, auto)
        margin: 0
        padding: 58px 0 0 0
    .itemBorder
        border-right: 1px solid #4e4e4e1f
        &:nth-child(2n)
            border: none
