@import ../../../../styles/helpers

.charts
    position: relative
    z-index: 2
    border: 1px solid $n6
    border-radius: 8px
    +t
        padding-top: 8px
    img
        width: 100%
    +dark
        border-color: $n2

.head
    display: flex
    justify-content: space-between
    align-items: center
    padding: 12px 16px
    background: $n8
    border-bottom: 1px solid $n6
    border-top-right-radius: 8px
    border-top-left-radius: 8px
    +m 
        padding: 4px
    +dark
        background: $dark
        border-color: $n2

.nav
    display: flex

// .group
//     .nav
//         +m
//             display: none

.dropdown
    display: none
    +m
        display: block

.dropdownHead
    height: 28px
    line-height: 28px
    background: $n6
    +dark
        background: $n3

.link
    display: flex
    justify-content: center
    align-items: center
    padding: 2px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: #ccc
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.chartLink
    display: flex
    justify-content: center
    align-items: center
    padding: 2px 12px
    border-radius: 14px
    background: #ccc
    +dark
        background: none
    transition: all .2s
    &.active
        background: hsl(0, 0%, 24%)
    &:not(:last-child)
        margin-right: 8px

.inner
    overflow: hidden
    height: calc( 100vh - 500px )
    +t
        height: fit-content
    +s
        height: 200px

.iframe
    height: calc(100% - 20px)
    margin: -1px
    +t
        position: relative
        z-index: -1
    .loaderContainer
        min-height: 100% 
        display: flex
        align-items: center
        justify-content: center
    .canvas-areaChart
        max-height: 384px

.chart
    width: 100%
    height: calc( 100% - 50px )
    background: $n8
    +dark
        background: $dark

.Charts_no_data
    height: 100px
    width: 100px
    iteam-align: center
    padding-top: 20px

.ChartContainer_height
    min-height: calc( 100vh - 500px )
    display: flex
    align-items: center
    flex-direction: column
    gap: 16px
    justify-content: center
    padding: 10px
    +s
        min-height: auto
        height: 200px

.ChartContainer_detail
    font-weight: 500
    font-size: 14px
    color: #777E91
    +dark
        color: $n6
